var VitoResetPass = function () {
    return {
        resetpass: null,
        getForm: function () {
            //  console.log(this.resetpass)
            return this.resetpass;
        },
        init: function (formselector) {
            if (formselector === undefined) {
                this.resetpass = $('#vt-resetpass-form')
            } else {
                this.resetpass = $(formselector);
            }

            this.addListener();

        },
        addListener: function () {
            var parent = this;
            this.resetpass.on('submit', function (event) {
                event.preventDefault();
                parent.sendRequest();

            })
        },
        sendRequest: function () {
            //  console.log(this.resetpass)
            url = this.resetpass.attr('action')
            method = this.resetpass.attr('method')

            form_data = {
                token: this.resetpass.find('.vt-token').val(),
                password: this.resetpass.find('.vt-password').val(),
                password_confirmation: this.resetpass.find('.vt-password_confirmation').val(),
            }

            // console.log(form_data)

            $.ajax({
                url: url,
                type: method,
                data: form_data,
                success: function (msg) {
                    if (msg.success === true) {
                        SuccessWriter.write(msg.message, true)
                        setTimeout(function () {
                            FormSwitcher.showForm(VitoLogin.getForm())
                            SuccessWriter.write(msg.login_message, true)
                        }, 1500)
                    } else {
                        ErrorWriter.write(msg.message, true)
                    }

                },
                error: function (msg) {
                    if (msg.status === 422) {
                        req_errors = $.parseJSON(msg.responseText)
                        ErrorWriter.cleanMessages();
                        for (var property in req_errors) {
                            if (req_errors.hasOwnProperty(property)) {
                                if (typeof req_errors[property] === 'string') {
                                    //    ErrorWriter.write(req_errors[property])
                                } else {

                                    for (key in req_errors[property]) {
                                        message = req_errors[property][key];
                                        ErrorWriter.write(message)
                                    }
                                }
                            }
                        }
                    }
                }
            });
        },
    }
}();